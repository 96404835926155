import { useState, useEffect, MouseEvent, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import { rootStore } from '../store';
import { Topic, TopicPayload } from '../types/content';
import { getTopics, createTopic, updateTopicInput } from '../actions/content';
import { EssayList } from './EssayList';


export const WordCloudBackground : React.FunctionComponent<React.ReactNode> = (props) => {
  const dispatch = useDispatch();
  const contentStore = useSelector((state: rootStore) => state.content)
  const [inter, setInter] = useState(null);
  const phrases = [
    'el espacio es para crear en \n y con el',
    'como se genera un "que estoy viendo?"',
    'nunca estoy seguro',
    'si hay otras opciones',
    'como se genera un "que estoy escuchando?"',
    'automatically humanize',
    'como se genera un "que estoy sintiendo?"',
    'no hay no opciones',
    'como se genera un "que estoy presenciando?"',
    'que pasa si cada uno de nosotros es un planeta/estrella',
    'como se genera un "que estoy tocando?"',
    'juega',
    'como se genera un "que estoy probando?"',
    'celebra',
    'como se genera un "que estoy oliendo?"',
    'diviertete',
    'quiero escuchar silencio y sentir la musica',
    'ser abierto para cerrarse',
    'lo digital es vida?',
    'no quiero toda tu atencion pero si quiero tu atencion',
    'como llegas a nuevos sonidos?',
    'como llegas a nuevos visuales?',
    'como llegas a nuevos sabores?',
    'como llegas a nuevos colores?',
    'como llegas a nuevos olores?',
  ]
  const [finalPhrases, setFinalPhrases] = useState([""]);

  useEffect(() => {
    const interval = setInterval(() => {
      addPhrase();
    }, 2500);
    return () => clearInterval(interval);
  // dispatch(getTopics())
  }, [finalPhrases])

  const addPhrase = () => {
    if(phrases.length){
      let phrase:string[] = phrases.splice(Math.floor(Math.random()*phrases.length), 1);
      console.log(phrase);
      // finalPhrases.concat(phrase)
      const phs = [...finalPhrases, ...phrase]
      setFinalPhrases(phs)
    } else {
      console.log('ebnd')
    }
    
  }
  // const handleClick = (event: MouseEvent) => {
  //   if(contentStore.topicInput === ""){
  //     return;
  //   }
  //   event.preventDefault();
  //   let topic: TopicPayload = {
  //     name: contentStore.topicInput
  //   }
  //   dispatch(createTopic(topic))
  // }


  return <div>
    <div className="bg-canvas">
      <div className="typewriter words">
        {finalPhrases.map((p)=>{
          return <h1 key={p}>{p}</h1>
        })}
      </div>      
    </div>
    {props.children}
  </div>
}
