import { useEffect } from "react";
import { WordCloudBackground } from '../components/WordCloudBackground'


export function Home() {

  // let particlesJS: any

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "https://cdn.jsdelivr.net/npm/particles.js@2.0.0/particles.min.js";
  //   script.async = true;
  //   script.onload = () => scriptLoaded();

  //   document.body.appendChild(script);
  // });

  // const scriptLoaded = () => {
  //   console.log(particlesJS);
  //   particlesJS.load('particles-js', 'assets/particles.json', function() {
  //     console.log('callback - particles.js config loaded');
  //   });
  // };

  return (
    <div className="App">
      <WordCloudBackground>
        <div id="particles-js">
          <header className="App-header">
            <h2>9</h2>
          </header>
        </div>
      </WordCloudBackground>
    </div>
  );
}