
import logo from '../logo.svg';
import { Link } from "react-router-dom";

export function LinkTree() {
  return (
    <div className="App">
      <header className="App-header">
        <h2>this is not a link tree</h2>
        <ul className="main-tree-list">
          {/* <li className="glow"><Link to="//www.youtube.com/erikiado" target="_blank">videos</Link></li>
          <li className="glow"><Link to="//soundcloud.com/erikiado" target="_blank"><span>&#127932;</span> soundcloud <span>&#127911;</span></Link></li>
          <li className="glow"><Link to="//www.mixcloud.com/erikiado" target="_blank"><span>&#127932;</span> mixcloud <span>&#127911;</span></Link></li>
          <li className="glow"><Link to="//www.youtube.com/channel/UCek0AZZe4NI9z59vg6qOpAg" target="_blank"><span>&#127932;</span> programacion <span>&#127911;</span></Link></li> */}
          
          <li className="glow"><Link to="//www.youtube.com/erikiado" target="_blank">tripin</Link></li>
          <li className="glow"><Link to="//soundcloud.com/erikiado" target="_blank">s0nidos</Link></li>
          <li className="glow"><Link to="//www.mixcloud.com/erikiado" target="_blank">mixxxes</Link></li>
          <li className="glow"><Link to="//www.youtube.com/channel/UCek0AZZe4NI9z59vg6qOpAg" target="_blank">aprende 9rogramacion</Link></li>
          <li className="glow"><Link to="//github.com/erikiado" target="_blank">programæs</Link></li>
          {/* <li className="glow"><Link to="//erikiado.com" target="_blank">erikiado.com<span>&#127911;</span></Link></li> */}
        </ul>
        <h4>it was a link list</h4>
      </header>
    </div>
  );
}